<template lang="pug">
.k-field(
  :is="component"
  :label="label"
  :type="type"
  :message="message"
  :grouped="grouped"
  :horizontal="horizontal"
  :addons="addons"
  :required="required"
  :skipLabel="skipLabel"
  :is-space-between="isSpaceBetween"
  :label-position="labelPosition"
  :options="options"
)
  template(#label)
    slot(name="label")
  slot
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import IsBField from './k_field/is-b-field.vue'
import Kolcenter from './k_field/kolcenter.vue'

const STYLE_TYPE_MAP = {
  'is-b-field': IsBField,
  kolcenter: Kolcenter
}

const props = defineProps({
  styleType: {
    type: String,
    default() {
      return 'is-b-field'
    }
  },
  label: { type: String },
  type: { type: String },
  message: { type: String },
  grouped: { type: Boolean },
  horizontal: { type: Boolean },
  addons: { type: Boolean },
  required: { type: Boolean },
  labelPosition: { type: String },
  skipLabel: { type: Boolean },
  isSpaceBetween: { type: Boolean },
  options: { type: Object }
})

const component = computed(() => {
  return STYLE_TYPE_MAP[props.styleType]
})
</script>
